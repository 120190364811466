/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/

html
	font-size: 16px
	scroll-behavior: auto

body
	font-family: $font-body
	font-size: .875rem
	color: $color-body

h1, h2, h3, h4, h5, h6
	font-family: $font-heading

svg
	display: inline-block

.ant-form-vertical .ant-form-item-label > label, .ant-col-24.ant-form-item-label > label, .ant-col-xl-24.ant-form-item-label > label
	color: $color-soft-gray

label
	font-size: 12px !important

input[type='text'], .ant-input-password, input[type='number'], input[type='password'], .ant-picker, .ant-select-single .ant-select-selector, .nepalify-input
	height: 29px !important
	border-radius: 2px

.ant-input, .ant-picker, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-password
	// height: 28px !important
	border: 1px solid $color-border

.ant-input-password .ant-input
	height: 100% !important

.ant-radio-inner
	border: 1px solid $color-border

.ant-radio-checked .ant-radio-inner
	border-color: $color-primary

.ant-radio-inner::after
	background-color: $color-primary

.ant-radio-checked::after
	border: 1px solid $color-primary

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner,
.ant-checkbox-wrapper:hover .ant-checkbox, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner
	border-color: $color-primary

.ant-checkbox-checked:after
	border: 1px solid $color-primary

.ant-checkbox-inner
	border: 1px solid $color-border

.ant-checkbox-checked .ant-checkbox-inner
	background-color: $color-primary
	border-color: $color-primary

.ant-input-group-addon
	font-size: 12px
	border: 1px solid $color-border

.ant-input-group-addon .ant-form-item
	height: 26px

.ant-input-group-addon .ant-select-arrow .anticon
	position: relative
	top: -1px
	left: -3px

.ant-select-arrow svg
	// color: $color-border !important
	color: $color-body

.ant-dropdown
	&--fixed-height
		.ant-dropdown-menu
			max-height: 200px
			overflow-y: scroll

.ant-dropdown-trigger
	&.fake-link
		&.ant-dropdown-open
			color: $color-primary

.ant-dropdown-menu
	min-width: 180px

.ant-modal-footer
	padding: 10px 20px
	margin-top: 10px

.ant-btn
	height: unset !important

.ant-btn-primary:hover, .ant-btn-primary:focus
	color: $color-primary !important
	border-color: $color-primary !important

// .ant-btn
// 	color: #fff !important

.ant-btn-primary:hover, .ant-btn-primary:focus
	color: #fff !important
	background: $color-primary !important
	border-color: $color-primary !important

.ant-btn-primary[disabled]:hover
	background-color: #f5f5f5 !important
	border-color: #d9d9d9 !important

.ant-btn-primary[disabled]:hover
	color: rgba(0, 0, 0, 0.25) !important
	border-color: #d9d9d9 !important

.ant-picker
	width: 100%

/***************************************************************************************************/
/***************************************** /.G.E.N.E.R.A.L *****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/***************************************** C.A.R.O.U.S.E.L *****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/**************************************** /.C.A.R.O.U.S.E.L ****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/********************************************* F.O.R.M *********************************************/
/***************************************************************************************************/

// .ant-input,
// .ant-input-password
// 	border-radius: $form-input-border-radius

.ant-input,
.ant-picker,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
	height: $form-input-height
	// border: 1px solid $color-border

.ant-form-item
	margin-bottom: 0.2rem

.ant-form-item-label
	padding-bottom: 0 !important

.ant-select-item-option-active:not(.ant-select-item-option-disabled)
	background-color: lighten($color-primary, 30%) !important

.ant-checkbox-indeterminate .ant-checkbox-inner::after
	background-color: $color-primary

.ant-btn
	color: $color-primary
	height: $btn-height
	border-color: $color-primary
	border-radius: $radius-general
	&-primary
		background-color: $color-primary
		color: #FFFFFF
		&:hover
			background-color: $color-tertiary
			border-color: $color-secondary
	&-sm
		height: 24px
	&-icon-only
		.anticon
			svg path
				fill: #FFFFFF

.ant-input-group-addon
	.ant-form-item
		margin-bottom: 0 !important

// NEPALI DATEPICKER
.nepali-date-picker
	&--top
		background: red
		.calender
			bottom: 36px !important
	.calender
		min-width: 16rem

.ant-pagination
	@media print
		display: none

/***************************************************************************************************/
/******************************************** F.O.R.M ********************************************/
/***************************************************************************************************/
.ant-layout-sider
	background: $color-secondary
	min-width: $sidebar-width !important
	max-width: $sidebar-width !important

.ant-layout-sider-collapsed
	min-width: 80px !important
	max-width: 80px !important

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-inline.ant-menu-sub
	background: $color-secondary

.ant-layout-header
	background: $color-secondary

.ant-menu-submenu-title
	padding-left: 18px !important

.ant-menu li ul li .ant-menu-submenu-title, .ant-menu li ul a li
	padding-left: 47px !important

.ant-menu li ul li ul li
	padding-left: 64px !important

.ant-menu li
	color: #fff !important

.ant-menu-dark .ant-menu-item:hover
	font-weight: bold

// .ant-menu-submenu li .ant-menu-submenu-title
// 	padding-left: 47px !important

// .ant-menu-submenu .ant-menu-sub .ant-menu-item
// 	padding-left: 64px !important

.ant-layout-sider-trigger
	background: $color-highlight
	min-width: $sidebar-width !important
	max-width: $sidebar-width !important

// .ant-menu-sub .ant-menu-item-only-child
// 	padding-left: 47px !important

// .ant-menu ul ul .ant-menu-item-only-child
// 	padding-left: 64px !important

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub
	color: #fff

.ant-menu-submenu-title
	max-height: 34px

.ant-menu-item
	max-height: 34px

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon
	font-size: 19px

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow
	opacity: 1

// Reset Disabled inputs

.ant-radio-disabled + span
	color: $color-body

.ant-input[disabled]
	background-color: initial
	border-color: initial
	border: 1px solid $color-border
	color: $color-body

.ant-picker.ant-picker-disabled
	background: initial
	boder-color: initial
	border: 1px solid $color-border

.ant-picker.ant-picker-disabled input
	color: $color-body

.ant-checkbox-disabled + span
	color: $color-body

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after
	border-color: #fff

.ant-checkbox-disabled .ant-checkbox-inner
	border-color: $color-border !important

.ant-checkbox-checked .ant-checkbox-inner
	border-color: $color-primary !important

.ant-radio-disabled .ant-radio-inner
	border-color: $color-border !important

.ant-radio-checked .ant-radio-inner
	border-color: $color-primary !important

.ant-radio-disabled .ant-radio-inner::after
	background-color: $color-primary

// Reset Font size
.ant-select
	font-size: 12px !important

.ant-select-item-option-content
	font-size: 12px

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
	background-color: $color-primary
	border-color: $color-primary

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child
	border-right-color: $color-primary

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before
	background-color: $color-primary

.ant-image
	max-width: 100%

/***************************************************************************************************/
/******************************************** /.F.O.R.M ********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/********************************************* T.A.B.S *********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** /.T.A.B.S ********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/********************************************* G.R.I.D *********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** /.G.R.I.D ********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** M.O.D.A.L ********************************************/
/***************************************************************************************************/

.ant-modal-body
	// padding: 0

.ant-modal-content
	.ant-modal-close
		color: #fff
	.ant-modal-header
		background-color: $color-primary
		padding: 10px 20px
		.ant-modal-title
			color: #fff

.ant-modal-close-x
	line-height: 44px

/***************************************************************************************************/
/******************************************* /.M.O.D.A.L *******************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/************************************** /.D.A.T.E.P.I.C.K.E.R **************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/*************************************** D.A.T.E.P.I.C.K.E.R ***************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/*************************************** P.A.G.I.N.A.T.I.O.N ***************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/************************************** /.P.A.G.I.N.A.T.I.O.N **************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/************************************ T.O.A.S.T - M.E.S.S.A.G.E ************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/*********************************** /.T.O.A.S.T - M.E.S.S.A.G.E ***********************************/
/***************************************************************************************************/

/***************************************************************************************************/
/****************************************** T.O.O.L.T.I.P ******************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/***************************************** /.T.O.O.L.T.I.P *****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/***************************************** C.O.L.L.A.P.S.E *****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/**************************************** /.C.O.L.L.A.P.S.E ****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** A.L.E.R.T ********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************* /.A.L.E.R.T *******************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** S.T.E.P.S ********************************************/
/***************************************************************************************************/

.ant-steps-item-process .ant-steps-item-icon
	background: $color-secondary !important
	border-color: $color-secondary

/***************************************************************************************************/
/******************************************* /.S.T.E.P.S *******************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** T.A.B.L.E ********************************************/
/***************************************************************************************************/

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td
	border-right-color: $color-darker

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td
	font-size: 12px
	border-bottom-color: $color-darker

.ant-table.ant-table-bordered > .ant-table-container
	border-left-color: $color-darker

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table
	border-top-color: $color-darker

.ant-table
	thead tr th
		white-space: nowrap

.ant-table-wrapper.clean
	th,
	td
		border-bottom: 0
	td
		padding-top: 4px !important
		padding-bottom: 4px !important

/***************************************************************************************************/
/******************************************* /.T.A.B.L.E *******************************************/
/***************************************************************************************************/