.deposit-detail-list
    li
        padding: 1rem 1rem
        &:nth-child(even)
            background: $color-lightest
        &:not(:last-child)
            border-bottom: 1px dashed $color-light

.group-wise-checkbox-wrapper
    margin-top: .5rem
    max-height: 10rem
    overflow-y: scroll
    .ant-checkbox-wrapper
        width: 100%
        margin: 0

.print-share-certificate
    font-size: 16px
    width: 800px
    background-color: #FFFFFF
    margin-top: 2rem
    margin-right: auto
    margin-left: auto
    padding: 3rem 2.5rem
    line-height: 32px
    text-align: justify
    @include box-shadow($box-shadow-general)
    border: 1px solid #DFDFDF
    @media print
        @include box-shadow(none)
        border: 0
        padding: 0
    &__header
        @include display-flex
        @include align-items(flex-end)
        &-right
            margin-left: auto
    &__body
        margin-top: 1rem
    &__field
        display: inline-block
        margin: 0 .5rem
        min-width: 8rem
        text-align: center
        border-bottom: 2px dotted #000