.dashboard
    &__stat
        .ant-col
            &:first-child
                .block-general
                    background: $color-green-light
                .dashboard-stat__icon
                    color: $color-green
            &:nth-child(2)
                .block-general
                    background: $color-pink-light
                .dashboard-stat__icon
                    color: $color-pink
            &:nth-child(3)
                .block-general
                    background: $color-blue-light
                .dashboard-stat__icon
                    color: $color-blue
            &:nth-child(4)
                .block-general
                    background: $color-purple-light
                .dashboard-stat__icon
                    color: $color-purple
    &__birthdays
        .block-general
            .user-data-one-line
                &:not(:last-child)
                    margin-bottom: .5rem
        &-title
            font-weight: 500
            color: $color-primary
            margin-bottom: 1rem
