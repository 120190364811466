.bottom-action-bar-fixed
    @include display-flex
    @include align-items(center)
    @include justify-content(flex-end)
    padding: 0 20px
    margin-top: 20px
    &__btn-group
        @include display-flex
        @include flex-direction(column)
        gap: 10px
        @media only screen and (min-width: #{$sm-screen + 1}px)
            @include flex-direction(row)
            gap: 15px
        .ant-btn
            // margin-bottom: 10px
            &:not(:last-child)
                margin-right: 0
