// Background Image Cover
%background-cover
	background-repeat: no-repeat
	background-size: cover
	background-position: center center

%box-shadow-general
	box-shadow: $box-shadow-general

%color-gradient
	background: $gradient-general
	-webkit-background-clip: text
	-webkit-text-fill-color: transparent

%block-white-basics
	background-color: #FFFFFF
	border: 1px solid $color-light-gray
	border-radius: $radius-general

%flex-center
	@include display-flex
	@include flex-direction(column)
	@include align-items(center)
	@include justify-content(center)
