.dashboard-subheader
    &__logo
        @include display-flex
        @include align-items(center)
        img
            max-width: 120px
    &__info
        margin-top: 10px
        @media only screen and (min-width: #{$md-screen + 1}px)
            margin-left: 2rem
            margin-top: 0
    &__header
        font-size: 18px
        font-weight: 700
        color: $color-secondary
    &__subheader
        font-weight: 500
        color: $color-primary
    &__detail
        margin-top: .25rem
        p
            margin-top: .125rem
    &__action
        @include display-flex
        @include flex-direction(column)
        @include align-items(flex-start)
        @include justify-content(flex-start)
        margin-top: 20px
        @media only screen and (min-width: #{$md-screen + 1}px)
            margin-left: auto
            margin-top: 0
            @include justify-content(flex-end)
            @include align-items(flex-end)
        p
            margin-top: .5rem
    .block-general
        @include display-flex
        @include flex-direction(column)
        @media only screen and (min-width: #{$md-screen + 1}px)
            @include flex-direction(row)
