.login-left
    @include display-flex
    @include flex-direction(column)
    @include align-items(center)
    text-align: center
    &__title
        font-size: 24px
        text-transform: uppercase
    &__body
        margin-top: 4rem
        margin-bottom: 6rem
    &__footer
        margin-top: auto
        *
            color: #FFFFFF
        &-primary,
        &-secondary
            font-size: 18px
            font-weight: 500
        &-secondary
            // font-size: 12px
            margin-top: .5rem