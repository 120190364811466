.dashboard-company-info
    height: 100%
    &__heading
        font-size: 20px
        font-weight: 500
        color: $color-secondary
    &__each
        margin-top: 1rem
        &-heading
            font-weight: 500
        p
            margin-top: .3125rem
    .block-general
        height: 100%