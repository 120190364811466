.breadcrumb
    &.block-general
        padding: 5px 16px
        margin-bottom: 12px

.app-layout-breadcrumb
    @media only screen and (min-width: #{$lg-screen + 1}px)
        @include display-flex
        @include align-items(center)
    &__mid
        margin-left: auto
    &__search
        margin-left: auto
        margin-right: 2rem
        .ant-select-auto-complete
            width: 12rem !important
    &__transactional-date
        label
            margin-right: .5rem
    &__left
        font-weight: 700
        color: $color-primary
    &__right
        // margin-left: auto
        @include display-flex
        @include align-items(center)

.app-layout-quick-menu
    @include display-flex
    @include align-items(center)
    &__label
        font-weight: 500
        margin-right: .5rem
    .ant-btn
        width: 24px
        height: 24px
        &:not(:last-child)
            margin-right: .5rem
        svg
            width: 14px
            height: 14px
