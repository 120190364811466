.btns
    width: 120px
    border-radius: 0 !important
    background: #eedfdf
    border-color: #bbaaaa !important
    color: #000000 !important
    margin: 5px 0px
.infobtn
    width: 205px
    border-radius: 0 !important
    background: #eedfdf
    color: #000000 !important
.borderinfo
    border-color: #f18810  !important



    