.print
    // padding: 2rem
    &__info
        display: none
        font-size: 10px
        @media print
            @include display-flex
    &__header
        display: none
        // @include display-flex
        // @include align-items(center)
        @media print
            @include display-flex
        &-left
            img
                width: 5rem
        &-mid
            text-align: center
            @include display-flex
            @include flex-direction(column)
            @include flex(1)
        &-right
            @include display-flex
            @include flex-direction(column)
            @include align-items(flex-end)
            @include justify-content(flex-end)
    &__body
        margin-top: 1rem
    &__enterprise-name
        font-size: 20px
        font-weight: bold
        margin-bottom: .25rem
    &__address
        font-weight: bold
        font-size: 16px
        margin-bottom: .25rem
    &__phone
        font-size: 14px
    &__title
        display: inline-block
        font-size: 18px
        font-weight: bold
        margin-top: 3rem
        // margin-bottom: .5rem
        @include align-self(center)
        border-bottom: 1px solid #000
    &__reg-no
        margin-bottom: .55rem
        margin-left: auto
        text-align: right
    &__up-to-date
        margin-top: .25rem
    &__footer
        margin-top: 4rem
        display: none
        @media print
            display: block
        &-each
            text-align: center
            padding-top: .5rem
            border-top: 1px dotted #444444
    &__settings
        .anticon
            color: $color-primary
            padding: .25rem
            cursor: pointer