/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/

html,
body,
#root
    min-height: 100vh

body
    line-height: 1.4

strong
    font-weight: bold

em
    font-style: italic

ul,
ol
    padding-left: 1.5rem
    list-style-position: inside
    &.clean
        padding-left: 0
        list-style: none !important

ul
    list-style-type: disc !important
ol
    list-style-type: decimal !important

@page
    margin: 20mm

.page-wrapper
    background-color: #FFFFFF
    padding: 1.5rem 2rem
    border: 1px solid $color-lightest
    @include box-shadow($box-shadow-block)

.container,
.container-xs,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl
    margin-right: auto
    margin-left: auto
    padding-right: $gutter
    padding-left: $gutter
    @include transition(max-width .2s)

.container-fluid
    padding-right: $gutter
    padding-left: $gutter

.container-xs,
.container--xs
    max-width: $container-xs-width !important

.container-sm,
.container--sm
    max-width: $container-sm-width !important

.container-md,
.container--md
    max-width: $container-md-width !important

.container
    max-width: $container-width

.container-lg,
.container--lg
    max-width: $container-lg-width !important

.container-xl,
.container--xl
    max-width: $container-xl-width !important

.container-xxl,
.container--xxl
    max-width: $container-xxl-width !important

.container-block
    padding: 2rem
    @extend %block-white-basics

.modal-title
    font-size: 2rem
    font-weight: 600
    margin-bottom: .75rem
    line-height: 40px

.modal-message
    margin-bottom: 1rem

.modal-spinner
    min-height: 12rem
    @extend %flex-center

.ant-modal--xl
    width: 1120px !important
    margin-top: -76px

.ant-modal--fullscreen
    position: fixed
    top: 1rem
    right: 1rem
    bottom: 1rem
    left: 1rem
    width: unset !important
    padding-bottom: 0
    .ant-modal-content
        height: 100%
        @include display-flex
        @include flex-direction(column)
        .ant-modal-body
            @include flex(1)

.preview-base
    background-color: #FFFFFF
    box-shadow: $box-shadow-block
    border-radius: $radius-general
    @include transition(box-shadow 0.1s)
    &:hover
        box-shadow: 0 3px 8px #EEEEEE
    &:focus
        outline: none

.ant-form-item--label-last
    .ant-form-item-control
        order: 1
    .ant-form-item-label
        order: 2
        margin-left: 1rem

.form-label-custom
    margin-bottom: 1rem

.form-label-custom--icon
    background-repeat: no-repeat
    background-size: contain
    background-position: 99%

.spin-wrapper
    height: 100%
    @include display-flex
    @include align-items(center)
    @include justify-content(center)

.dropdown-general
    .ant-dropdown-menu-item,
    a
        @include display-flex
        @include align-items(center)
        svg
            margin-right: 1rem

// FORM

.form-item-collapsed
    margin-bottom: 0 !important
    .ant-form-item-control-input
        min-height: unset

.form-item-inline
    .ant-row
        @include display-flex
        @include flex-direction(row)
        @include align-items(center)


.ant-input--fake
    height: 29px !important
    line-height: 20px !important

.ant-form-item--general
    .ant-form-item-control-input,
    .ant-form-item-control-input .ant-input
        border-radius: $form-input-border-radius--general

.ant-input--general
    border-radius: $form-input-border-radius--general !important

.ant-input--highlight
    &-secondary
        background: lighten($color-secondary, 45%)
    &-primary
        background:  lighten($color-primary, 35%)

.ant-btn
    &--squared
        border-radius: $radius-general !important
    &--warning
        background-color: $color-tomato !important
        color: #FFFFFF !important
        border-color: darken($color-tomato, 2%) !important
        &-outlined
            color: $color-tomato
            border-color: $color-tomato
    &--fluid
        width: 100%
        max-width: 100%

.ant-btn-icon
    @include display-flex
    @include align-items(center)
    svg
        margin-left: .6125rem
    &--left
        svg
            margin-left: 0
            margin-right: .6125rem

    &.ant-btn-primary
        svg,
        svg path
            stroke: #FFFFFF

.ant-btn-secondary
    background-color: $color-secondary
    color: #FFFFFF !important
    border-color: $color-secondary !important
    &:hover,
    &:active,
    &:focus
        background-color: darken($color-secondary, 10%)
        border-color: darken($color-secondary, 10%)
        color: #FFFFFF

.btn-group
    .ant-btn
        &:not(:last-child)
            margin-right: .75rem

.form-section-title
    font-size: 16px
    font-weight: 500
    color: $color-primary
    margin-bottom: 1.25rem

.addon-rs
    .ant-input-group-addon
        padding: 0 13px

// HEADING

.page-heading
    font-size: 18px
    font-weight: 500
    color: #3F3D56

// TABS
.tabs-general
    @include display-flex
    border-bottom: 2px solid $color-lighter
    .tabs-general__tab
        @include display-flex
        @include align-items(center)
        font-weight: 600
        color: inherit
        margin-bottom: -2px
        padding: 1rem 0
        border-bottom: 2px solid transparent
        &:hover
            color: $color-primary
        &.active
            // font-weight: 600
            color: $color-primary
            border-bottom-color: $color-primary
            svg,
            svg path,
            svg circle
                stroke: $color-primary
            &:not(.tabs-general__tab--icon-no-fill)
                svg,
                svg path
                    fill: $color-primary
        &:not(:last-child)
            margin-right: 6rem
        svg
            stroke: $color-cool-gray
            margin-right: .75rem

// DROPDOWN
.dropdown-menu-general
    padding: 16px 16px
    border-radius: $radius-block
    &--collapsed
        li
            padding: 5px 12px !important
    &--centered
        text-align: center
        li
            @include justify-content(center)
    &--large
        padding: 24px
    li
        color: $color-primary
        padding: 10px 15px
        @include display-flex
        @include align-items(center)
        border-radius: $radius-alt
        &.ant-dropdown-menu-item-danger
            svg
                path
                    stroke: $color-error
            &:hover
                span
                    color: #FFFFFF
                svg
                    path
                        stroke: #FFFFFF
        a
            color: $color-primary !important
            margin: 0
            padding: 0
            @include display-flex
            @include align-items(center)
        span
            color: $color-body
        svg
            width: 20px
            height: 20px
            margin-right: .8125rem

small,
.small
    font-size: 12px
    line-height: 1.4

.block-general
    margin-bottom: $gutter
    padding: 1.25rem
    @extend %block-white-basics
    &--sm
        padding: 1rem
    &--primary
        background-color: lighten($color-primary, 42%)
    &--secondary
        background-color: lighten($color-secondary, 52%)
    .ant-table
        background: transparent
        &-thead
            tr th
                background: transparent
    

.block-general-titled
    @extend %block-white-basics
    border: 1px solid $color-primary
    // border: none
    // box-shadow: 0 0 2px $color-primary
    padding-bottom: 20px
    &--title
        font-size: 14px
        font-weight: bold
        background: $color-primary
        padding: 10px 10px
        color: #fff
        border-radius: 6px 6px 0 0
    &--blue-title
        background: $color-secondary
        padding: 10px
        font-weight: bold
        color: #fff
        border-radius: 6px 6px 0 0
    &--body
        padding: 20px
        padding-top: 0
        padding-bottom: 0

.block-distinct
    background-color: $color-lightest
    padding: 1rem

// TITLE
.colored-title
    color: $color-primary
    margin-top: 15px
    font-weight: bold

// ADD CUSTOM MARGIN
.margin-top
    margin-top: 10px

.avatar
    width: 100%
    display: flex
    // justify-content: center
    flex-direction: column
    // align-items: center
    &--pic
        min-width: 100px
        max-width: 120px
        height: 120px
        border: 1px dashed $color-secondary
        margin-top: 1px
        margin-bottom: 10px
        display: flex
        align-items: center
        justify-content: center
        img
            width: 100%
            height: 100%
            object-fit: cover
    &--upload-button
        max-width: 120px
        display: flex
        justify-content: center

// TABLE
.ant-table-wrapper
    &--collapsed
        th.ant-table-cell
            padding-top: .25rem !important
            padding-bottom: .25rem !important
        td.ant-table-cell
            padding-top: 0 !important
            padding-bottom: 0 !important
            // border-bottom: 0
    &--link
        tr
            &:hover
                a
                    background-color: lighten($color-primary, 10%) !important
                    color: #FFFFFF
        td
            @include transition(unset !important)
            &.ant-table-cell
                padding: 0 !important
        .ant-table-cell
            &-link
                padding-right: 1rem !important
                padding-left: 1rem !important
            a
                padding-right: 1rem !important
                padding-left: 1rem !important
                display: block
                color: $color-body
                @include transition(unset)

table.table-primary,
table.table-primary tr,
table.table-primary th,
table.table-primary td
    border: 1px solid #000

table.table-primary
    width: 100%
    a
        display: block
        color: $color-body
        @include transition(unset)
        &:hover
            background-color: $color-primary
            color: #FFFFFF
    th, td
        padding: 1px 8px
        &.td-link
            padding: 0
            a
                padding: 1px 8px
    th
        background-color: $color-lightest
        padding-top: 4px
        padding-bottom: 4px
        text-align: left

.fake-link
    // color: $color-primary
    @include transition(unset)
    cursor: pointer
    &:hover
        color: $color-primary

.table-th-single-line
    thead th
        white-space: nowrap

// CARD
.ant-card
    &--header-bg
        .ant-card-head
            background-color: $color-secondary-light
            color: $color-secondary-dark

/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/
