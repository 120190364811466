.daily-collection-form
    margin-bottom: .75rem
    padding-right: calc($gutter / 2)
    padding-left: calc($gutter / 2)
    &__row
        @include display-flex
        margin-right: -$gutter
        margin-left: -$gutter
    &__col
        @include flex(0 0 20%)
        max-width: 20%
        padding-right: calc($gutter / 2)
        padding-left: calc($gutter / 2)