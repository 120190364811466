.member-registration
    // padding: 3.5rem 4rem
    &__steps
        background-color: $color-lightest
        padding-top: 2.5rem
        padding-bottom: 2.5rem
        .ant-steps-item
            &-finish
                .ant-steps-item-icon
                    background-color: $color-success
                    border-color: $color-success
                    svg path
                        fill: #FFFFFF
            &-title::after
                background-color: $color-light !important
        &-body
            padding-top: 3rem
            padding-bottom: 4rem
    &-step
        &__header
            font-size: 18px
            text-align: center
            color: $color-secondary
            text-transform: capitalize
        &__form
            margin-top: 2.5rem


.member-registration-header,
.member-registration__steps,
.member-registration__steps-body
    padding-right: 4rem
    padding-left: 4rem