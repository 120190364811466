.login-right
    @include flex(1)
    &__heading
        font-size: 18px
        color: $color-primary
        margin-bottom: 1.5rem
    &__logo
        margin-bottom: 4rem
        img
            max-width: 84px
    &__register
        margin-top: .5rem
        text-align: center
        a
            color: $color-secondary