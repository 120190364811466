// F.O.N.T.S
$font-body: 'Roboto', sans-serif, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
$font-heading: 'Roboto', sans-serif, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"

// C.O.L.O.R.S
// Color Body
$color-body: #4C4C4C
$color-border: #acacac
// Color Light
$color-light: #C3C3C3
$color-lighter: #E8E8E8
$color-lightest: #F8F8F8
// $color-lightest: #F1F2F3
// Color Dark
$color-dark: #929292
$color-darker: #626262
$color-darkest: #272727
// Color Primary
$color-primary: #F26722
$color-primary-light: #FFD4BF
$color-primary-lighter: transparentize($color-primary, 0.75)
$color-primary-lightest: transparentize($color-primary, 0.9)
$color-primary-hover: #f85a0b
// Color Secondary
$color-secondary: #3A6EA5
$color-secondary-light: #DBEBF8
$color-secondary-dark: darken($color-secondary, 10%)
// Color Highlight
$color-highlight: #154E8B
// Color tertiary
$color-tertiary: #222222
// Color Success
$color-success: #2AC769
$color-success-dark: darken($color-success, 5%)
$color-success-darker: darken($color-success, 15%)
$color-success-darkest: darken($color-success, 30%)
// Color Error
$color-error: #FB4E4E
$color-error-dark: darken($color-error, 5%)
$color-error-darker: darken($color-error, 15%)
$color-error-darkest: darken($color-error, 30%)
// Color Warning
$color-warning: #F6A609
$color-warning--alt: #FF5400
// C.o.l.o.r T.o.m.a.t.o
$color-tomato: #AB3F1B
// Color Text Mute
$color-text-mute: #ADB3BB
// gray
$color-gray: #E0E0E0
// Cool gray
$color-cool-gray: #73788F
// Soft gray
$color-soft-gray: #4e4e4e
// Deep gray
$color-deep-gray: #A8B0BF
// Light gray
$color-light-gray: #F6F6F6
// Smooth gray
$color-smooth-gray: #3F4347
// New gray
$color-new-gray: #8296A9
// Dark blue
$color-dark-blue: #000F2B
// Dark bg
$color-dark-bg: #2A2E33
// New dark
$color-new-dark: #222222
// Outlines
$color-outlines: #DADCE0

// STAT COLORS DASHBOARD
$color-green: #71F39B
$color-green-light: #BFF3D0
$color-pink: #F14F80
$color-pink-light: #F3BFCF
$color-blue: #4EDFF3
$color-blue-light: #BFEDF3
$color-purple: #7657F2
$color-purple-light: #C9BFF3

// G.U.T.T.E.R
// Gutter General
$gutter: 20px
$gutter-large: 30px
// Gutter Half
$gutter-half: calc($gutter / 2)
// Gutter Quarter
$gutter-quarter: calc($gutter / 4)

// B.O.R.D.E.R - R.A.D.I.U.S
$radius-general: 6px
$radius-alt: 5px
$radius-medium: 3px
$radius-large: 5px
$radius-block: 15px

// B.O.X - S.H.A.D.O.W
$box-shadow-general: 0 2px 4px transparentize(#000000, .9)
$box-shadow-nav: 0 2px 2px #ECECEC
$box-shadow-nav-dark: 0 1px 10px #000000
$box-shadow-block: 0 1px 8px #E0E0E0
$box-shadow-btn: 0 1px 2px #00000015
// $box-shadow-popups: 0 5px 20px #00000015
$task-cards-shadow: 0 0.5px 1px #00000030

// G.R.A.D.I.E.N.T
// $gradient-general: linear-gradient(99.34deg, #2196F3 0.93%, #9C27B0 52.03%);
$gradient-general: linear-gradient(to right, $color-primary, #0065b5)

// W.I.D.T.H
$side-navigation-width: 280px
$container-xs-width: 480px
$container-sm-width: 720px
$container-md-width: 850px
$container-width: 994px
$container-lg-width: 1240px
$container-xl-width: 1440px
$container-xxl-width: 1840px

// H.E.I.G.H.T
$main-nav-height: 52px
$property-nav-height: 52px
$form-input-height: 34px
$btn-height: 34px

// F.O.R.M
$form-input-background: #FFFFFF
$form-input-background--alt: #FAFAFA
$form-input-border: $color-lighter
$form-input-border--alt: #f2f2f2
$form-input-border-radius: $radius-general
$form-input-border-radius--general: $radius-alt

// M.O.D.A.L
$modal-title-font-size: 1.5rem
$modal-border-radius: 5px

// T.R.A.N.S.I.T.I.O.N
// Transition duration general
$transition-duration-general: 0.3s
// Transition timing function general
$transition-timing-function-general: ease-out
// Transition general
$transition-general: all $transition-duration-general $transition-timing-function-general
// Transition duration fast
$transition-duration-fast: 0.2s

// S.I.D.E.B.A.R M.E.N.U
$sidebar-width: 243px
